export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'event_banner', label: "Banner", _style:'min-width:100px' },
    { key: 'event_name', label: "Nama Event", _style:'min-width:100px' },
    { key: 'price', label: "Harga", _style:'min-width:100px' },
    { key: 'admin_fee', label: "Biaya Admin", _style:'min-width:100px' },
    { key: 'account_name', label: "Nama Account", _style:'min-width:100px' },
    { key: 'account_number', label: "Nomor Account", _style:'min-width:100px' },
    { key: 'bank_name', label: "Nama Bank", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
